.App {
  text-align: center;
}

.App-header {
  background: rgb(27, 26, 32);
  background-image: linear-gradient(
        135deg,
        rgb(0, 0, 0) 0%, 
        rgba(0, 0, 0, 0.2) 30%, 
        rgba(0, 0, 0, 0) 50%, 
        rgba(0, 0, 0, 0.2) 70%, 
        rgb(0, 0, 0) 100%
  );
  animation: gradient 10s infinite;
  background-position: top left;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.peashooter-header {
  background-position: top left;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.peashooter-video {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: 1;
}

@keyframes gradient {
  0% {background-color: rgb(27, 26, 32);}
  50% {background-color: rgb(29, 27, 56);}
  100% {background-color: rgb(27, 26, 32);}
}

.blck {
  max-width: 65%;
  max-height: calc(500px + 2vmin);
  width: auto;
  height: auto;
  margin-top: 16px;
  margin-bottom: 8px;
  border-radius: 16px;
}

.hover {
  transition: border-radius 0.1s, transform 0.1s;
}

.hover:hover {
  border-radius: 2px;
  transform: scale(1.03, 1.03) rotate(0.01deg);
}

.youtube {
  max-width: 65%;
  max-height: 100vh;
  margin-top: 8px;
  border-radius: 16px;
}

.text {
  -webkit-text-fill-color: #c4c4c4c4;
}

.main-title {
  position: relative;
  transform: translateY(-100%);
}

.button {
  text-decoration-color: #00000000;
  -webkit-text-fill-color: #b6b6b6b6;
  font-size: 65%;
  border-radius: 16px;
  border: 2px solid #b6b6b6b6;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff00;
  margin-bottom: 15px;
  transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out;
}

.back-button {
  position: absolute;
  left: 8px;
  top: 8px;
}

.button:hover {
  transform: scale(1.15, 1.15) rotate(0.01deg);
  background-color: #8b90dd1a;
}

.link {
  color: #b6b6b6b6;
  margin-bottom: 1%;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, transform 0.1s ease-in-out, border-radius 0.15s ease-in-out;
  border-radius: 16px;
}

.link:hover {
  transform: scale(1.05, 1.05) rotate(0.01deg);
  color: #bbbbbbbb;
  background-color: #bbbbbb22;
  border-radius: 4px;
}

.info-text {
  position: absolute;
  flex: 1;
  z-index: 2;
}

#gambling-history {
  font-size: large;
}

#click-to-start-video {
  -webkit-text-fill-color: black;
  position: absolute;
}

.blck-download-button {
  border: 4px solid #aa0000;
  transition: border-radius 0.1s, transform 0.1s;
  background-color: rgb(252, 65, 65);
  border-radius: 20px;
  width: 330px;
  height: 70px;
  font-size: xx-large;
}

.blck-download-beta-button {
  border: 4px solid #70a04f;
  transition: border-radius 0.1s, transform 0.1s;
  background-color: rgb(93, 250, 120);
  border-radius: 20px;
  width: 250px;
  height: 60px;
  font-size: medium;
}

.comic-sans {
  font-family: "Comic Sans MS", "Comic Sans", cursive;
}

.blck-download-button:hover {
  transform: scale(1.07, 1.07) rotate(0.01deg);
  border-radius: 12px;
}

.blck-download-beta-button:hover {
  transform: scale(1.07, 1.07) rotate(0.01deg);
  border-radius: 12px;
}

.blck-download-header {
  background-color: #AAE5A4; /* rule34 green :) */
  background-position: top left;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  font-size: larger;
  font-size: calc(3px + 2vmin);
}



.clickable:active {
  transform: scale(0.95, 0.95) rotate(0.01deg);
}
